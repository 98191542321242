/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import api from '~/services/api';
import { Col, Row } from 'react-bootstrap';

interface FormDefaultProps {
  codTela: number;
}

const FooterDefault: React.FC<FormDefaultProps> = (props) => {
  const { children, codTela } = props;
  const [linkMaterial, setLinkMaterial] = useState('');
  useEffect(() => {
    (async () => {
      const { data } = await api.get(`telasLinkMaterial/${codTela}`);

      if (data) {
        setLinkMaterial(data.data.link_material);
      } else {
        setLinkMaterial('');
      }
    })();
  }, [codTela]);
  return (
    <Container>
      <Row className="rowContainerFooter">
        <Col sm={12} md={9} className="containerChild">
          {children}
        </Col>
        <Col className="containerTitle" sm={12} md={3}>
          {linkMaterial ? (
            <div className="containerMaterial">
              <a href={linkMaterial} target="_blank" className="linkMaterial">
                Precisa de ajuda?
              </a>
            </div>
          ) : (
            <div style={{ display: 'hidden' }} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FooterDefault;
