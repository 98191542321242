import styled from 'styled-components';
import Form from '@atlaskit/form';

export const Container = styled.div`
  padding: 10px 10px;
`;
export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: 16px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  margin-bottom: 5px;
`;

export const Content = styled(Form)`
  padding: 10px 0px;
`;

export const Footer = styled.div`
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: 20px;
  .containerButtons {
    width: 100%;
    display: flex;
    flex-direction: space-between;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    align-content: flex-end;
    .linkMaterial {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .left {
      margin-right: auto;
      align-self: flex-end;
    }
    .right {
      order: 2;
      width: 100%;
      display: flex;
      flex: 2;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .containerMaterial {
    display: flex;
    flex: 1;
    flex-direction: space-between;
    align-items: flex-end;

    .linkMaterial {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .left {
      align-self: flex-end;
    }
    .right {
      // width: 100%;
      display: flex;
      flex: 2;
      justify-content: flex-end;
      align-items: center;
      background: red;
    }
  }

  /* .containerMaterial {
      min-width: fit-content;
      width: auto;
      padding: 0px 4px;
      order: 1;
      margin: 0px 64px 2px 0px;
    } */
  @media (max-width: 992px) {
    flex-direction: column;
    button {
      margin: 4px;
    }
    .containerButtons {
      flex-direction: column;
      align-items: flex-start;
    }
    .containerMaterial {
      order: 2 !important;
      margin-top: 16px;
    }
    .right {
      order: 1 !important;
      flex-direction: column;
      align-items: flex-start;
      max-width: fit-content;
    }
    .left {
      margin-left: 0 !important;
    }
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.duplicate {
    background: #007bff;
    width: 300px;
  }
  &.download {
    background: #007bff;
  }

  &.return-search {
    background: #ffc107;
    width: 190px;
  }

  &.limpar {
    background: #ffc107;
  }

  &.cancelar {
    background: #dc3545;
  }

  &.novo {
    background: #007bff;
  }
  &.receipt {
    background: #000000;
  }

  &.danfe {
    background: #007bff;
  }

  &.delete {
    background: #dc3545;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
