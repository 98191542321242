import React, { useState, useEffect, useCallback } from 'react';

import api from '~/services/api';
import InputSelect from '../Inputs/InputSelect';

interface Options {
  label: string | undefined;
  value: number | string | undefined;
}

interface Finalizadora {
  cod_finalizadora: number;
  des_finalizadora: string;
}

interface FinalizadoraLoja {
  num_condicao: number;
  cod_condicao: number;
  cod_cc: number;
  cod_categoria: number;
  finalizadora: Finalizadora;
}

interface FinalizadoraProps {
  value: Options | undefined | null;
  loja: number[] | undefined | number;
  onChange(val: any, data: boolean): void;
  isRequired?: boolean;
  isDisabled?: boolean;
  setInvalid?: boolean;
  initialized?: boolean;
  placeholderFinalizadora?: string;
}

const Finalizadora: React.FC<FinalizadoraProps> = (props) => {
  const {
    loja,
    onChange,
    value,
    isRequired,
    isDisabled,
    setInvalid,
    initialized,
    placeholderFinalizadora = '- Selecione a Finalizadora -',
  } = props;

  const [optionsFinalizadora, setOptionsFinalizadora] = useState<Options[]>([]);
  const [optionsFinalizadoraValue, setOptionsFinalizadoraValue] = useState<
    Options[]
  >([]);

  useEffect(() => {
    const getLojas = async () => {
      const res = await api.get(`/finalizadora-loja/${loja}`);
      const { success, data, message } = res.data;
      if (success) {
        const arrayOrder = data.sort(function (a: any, b: any) {
          if (
            a.finalizadora.des_finalizadora < b.finalizadora.des_finalizadora
          ) {
            return -1;
          }
          if (
            a.finalizadora.des_finalizadora > b.finalizadora.des_finalizadora
          ) {
            return 1;
          }
          return 0;
        });

        // Tira as finalizadoras que estão inativas
        const optionsWithoutInativa = arrayOrder.filter(
          (item: any) => item.flg_inativa === false,
        );

        const options = optionsWithoutInativa.map(
          (finalizadoraLoja: FinalizadoraLoja) => ({
            label: `${finalizadoraLoja.finalizadora.des_finalizadora}`,
            value: finalizadoraLoja.finalizadora.cod_finalizadora,
            num_condicao: finalizadoraLoja.num_condicao,
            cod_condicao: finalizadoraLoja.cod_condicao,
          }),
        );
        const optionsValue = arrayOrder.map(
          (finalizadoraLoja: FinalizadoraLoja) => ({
            label: `${finalizadoraLoja.finalizadora.des_finalizadora}`,
            value: finalizadoraLoja.finalizadora.cod_finalizadora,
            num_condicao: finalizadoraLoja.num_condicao,
            cod_condicao: finalizadoraLoja.cod_condicao,
          }),
        );

        setOptionsFinalizadora(options);
        setOptionsFinalizadoraValue(optionsValue);
      }
    };
    if (loja) {
      getLojas();
    }
  }, [loja]);

  const handleChangeFinalizadora = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      onChange(val, isInvalid);
    },
    [onChange],
  );

  return (
    <>
      <div className="col-sm-12">
        <InputSelect
          placeholder={
            isDisabled === true
              ? 'Informe a Loja Primeiro'
              : placeholderFinalizadora
          }
          noOptionsMessage="Nenhum registro encontrado"
          options={optionsFinalizadora}
          label="Finalizadora"
          value={
            optionsFinalizadoraValue.find((finalizadora: Options) => {
              return finalizadora.value === value?.value;
            }) ?? null
          }
          isRequired={isRequired}
          setInvalid={setInvalid}
          isDisabled={isDisabled}
          iniInicializado={initialized}
          onChange={(newValue: any, isInvalid = true) => {
            handleChangeFinalizadora(newValue, isInvalid);
          }}
        />
      </div>
    </>
  );
};

export default Finalizadora;
