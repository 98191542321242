import styled from 'styled-components';

export const Container = styled.div`
    padding: 10px 10px;
  }

  .rowContainerFooter {
    flex-direction: row-reverse
  }

  .containerTitle{
    margin-top: 10px;
    white-space: nowrap;
    padding-left: 15px;
    display: flex;
    align-items: center;

    .linkMaterial {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .containerChild{
    display: flex;
    justify-content: right;
    align-items: flex-end;
    padding-left: 15px;
  }
`;
